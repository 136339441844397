<template>
  <div>
    <!-- PERMOHONAN DUA -->
    <b-row>
      <!-- Col: Left -->
      <b-col cols="12" xl="9" md="9">
        <b-tabs
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Data Dasar</span>
            </template>
            <!-- {{masterPropinsi}}
              <br>
              jenisDok
              <br>
              {{masterJenisKartuIdentitas}} -->
            <biodata-data-dasar
              v-if="masterBiodata"
              :master-biodata.sync="masterBiodata"
              :master-negara="masterNegara"
              :master-jenis-kartu-identitas="masterJenisKartuIdentitas"
              :master-agama="masterAgama"
              :master-gender="masterGender"
              :master-struktur-organisasi="masterStrukturOrganisasi"
            />
            <!-- <h3 v-else>
              {{ loadingMessage }}
              <element-log-permohonan
                v-if="masterPermohonan && noAccess"
                :master-biodata="masterBiodata"
                :master-permohonan="masterPermohonan"
              />
            </h3> -->
          </b-tab>
          <b-tab v-if="showTab.foto">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Foto</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
              <!-- <b-alert show variant="warning">Silahkan lengkapi data dasar terlebih dahulu</b-alert> -->
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarFoto"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '22', allowAddNew: false }"
            />
          </b-tab>
          <b-tab v-if="showTab.ijasah">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Riwayat Pendidikan</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarIjasah"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '23', allowAddNew: true }"
            />
          </b-tab>
          <b-tab v-if="showTab.pkpa">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Sertifikat PKPA</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarPKPA"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '1', allowAddNew: false }"
            />
          </b-tab>
          <b-tab v-if="showTab.upa">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Sertifikat UPA</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarUPA"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '2', allowAddNew: false }"
            />
          </b-tab>
          <b-tab v-if="showTab.magang">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Magang</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarMagang"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '3', allowAddNew: true }"
            />
          </b-tab>
          <b-tab v-if="showTab.pengangkatanAdvokat">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Pengangkatan Advokat</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarPengangkatanAdvokat"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '4', allowAddNew: false }"
            />
          </b-tab>
          <b-tab v-if="showTab.sumpah">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Pengambilan Sumpah</span>
            </template>
            <!-- <biodata-pkpa /> -->
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDaftarBas"
              :biodata-i-d="masterBiodata.id"
              :config="{ initWithJenisDokumen: '5', allowAddNew: false }"
            />
          </b-tab>
          <b-tab v-if="showTab.nia">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Nomor Induk Anggota (NIA)</span>
            </template>
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <!-- <div v-else>
                Not A/B/C
              </div> -->
            <biodata-nia
              v-if="masterBiodata && masterBiodata.id"
              :master-biodata="masterBiodata"
            />
          </b-tab>
          <b-tab v-if="showTab.lainnya">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Dokumen Lainnya</span>
            </template>
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <!-- <div v-else>
                Not A/B/C
              </div> -->
            <biodata-daftar-dokumen
              v-if="showDokumen"
              :master-biodata="masterBiodata"
              :master-daftar-dokumen="masterDokumenLainnya"
              :biodata-i-d="masterBiodata.id"
              :config="{
                selectedTipe: '2',
                allowAddNew: false,
                disableDelete: true,
              }"
            />
          </b-tab>
          <b-tab v-if="showTab.buktiBayar">
            <template #title>
              <feather-icon icon="AwardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Pembayaran</span>
            </template>
            <div v-if="!masterBiodata">
              {{ loadingMessage }}
            </div>
            <div v-else-if="!masterBiodata.id">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <span>Silahkan lengkapi data dasar terlebih dahulu</span>
                </div>
              </b-alert>
            </div>
            <!-- <div v-else>
                Not A/B/C
              </div> -->
            <element-daftar-bukti-bayar
              v-if="masterPermohonan && masterBiodata"
              :master-permohonan="masterPermohonan"
              :master-biodata="masterBiodata"
            />
          </b-tab>
        </b-tabs>
      </b-col>

      <!-- Col: RIGHT -->
      <b-col cols="12" xl="3" md="3">
        <element-actions
          v-if="masterBiodata && masterPermohonan"
          :master-biodata="masterBiodata"
          :jenis-permohonan-i-d="jenisPermohonanId"
          :master-permohonan="masterPermohonan"
        />
        <element-log-permohonan
          v-if="masterPermohonan"
          :master-biodata="masterBiodata"
          :master-permohonan="masterPermohonan"
        />
        <element-checklist-persyaratan
          v-if="masterDaftarPersyaratan.length > 0"
          :biodata-i-d="masterBiodata.id"
          :jenis-permohonan-id="params.jenisPermohonanID"
          :master-permohonan="masterPermohonan"
        />
        <element-checklist-verifikasi
          v-if="masterDaftarPersyaratan.length > 0 && showChecklistVerifikasi()"
          :biodata-i-d="masterBiodata.id"
          :jenis-permohonan-id="params.jenisPermohonanID"
        />
      </b-col>
    </b-row>
    <sidebar-verifikasi-dokumen />
    <sidebar-status-permohonan />
    <sidebar-status-perbaikan />
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import { BTabs, BTab, BAlert, BRow, BCol } from 'bootstrap-vue'
import { getCacheableApi, api } from '@/hap-sia/setup'
import SidebarVerifikasiDokumen from '@/views/hap/shared/SidebarVerifikasiDokumen.vue'
import SidebarStatusPermohonan from '@/views/hap/shared/SidebarStatusPermohonan.vue'
import SidebarStatusPerbaikan from '@/views/hap/shared/SidebarStatusPerbaikan.vue'
import ElementDaftarBuktiBayar from '@/views/hap/shared/ElementDaftarBuktiBayar.vue'
import BiodataDataDasar from '../shared/BiodataDataDasar.vue'
import BiodataDaftarDokumen from '../shared/BiodataDaftarDokumen.vue'
import BiodataNia from '../shared/BiodataNia.vue'
import ElementActions from './ElementActions.vue'
import ElementChecklistPersyaratan from './ElementChecklistPersyaratan.vue'
import ElementChecklistVerifikasi from './ElementChecklistVerifikasi.vue'
import ElementLogPermohonan from '../shared/ElementLogPermohonan.vue'
import {
  preloadMasterData,
  isRoleAllowed,
  getMyBiodataID,
  cekEksistingPermohonan,
  getEksistingDraftPermohonan,
  newBiodata,
} from './usePermohonan'

export default {
  components: {
    BTabs,
    BTab,
    BiodataDataDasar,
    // BiodataPkpa,
    BiodataDaftarDokumen,
    BiodataNia,
    SidebarVerifikasiDokumen,
    BAlert,
    BRow,
    BCol,
    ElementActions,
    ElementChecklistPersyaratan,
    ElementChecklistVerifikasi,
    ElementDaftarBuktiBayar,
    SidebarStatusPermohonan,
    SidebarStatusPerbaikan,
    ElementLogPermohonan,
  },
  data() {
    return {
      masterBiodata: null,
      masterPropinsi: null,
      masterJenisDokumen: null,
      masterJenisKartuIdentitas: null,
      masterNegara: null,
      masterGender: null,
      masterStrukturOrganisasi: null,
      masterAgama: null,
      loadingMessage: 'Loading...',
      masterDaftarFoto: [],
      masterDaftarPKPA: [],
      masterDaftarUPA: [],
      masterDaftarIjasah: [],
      masterDokumenLainnya: [],
      masterDaftarMagang: [],
      masterDaftarPengangkatanAdvokat: [],
      masterDaftarBas: [],
      masterDaftarPersyaratan: [],
      noAccess: false,
      showTab: {
        foto: false,
        ijasah: false,
        pkpa: false,
        upa: false,
        magang: false,
        pengangkatanAdvokat: false,
        sumpah: false,
        nia: false,
        lainnya: false,
        buktiBayar: false,
      },
      jenisPermohonanId: this.$route.params.jenis_permohonan_id,
      masterPermohonan: null,
      params: {
        mode: null,
        biodataID: null,
        jenisPermohonanID: null,
        permohonanID: null,
      },
      showDokumen: false,
    }
  },
  beforeCreate() {
    emitter.all.clear()
  },
  async mounted() {
    // simulasi userData
    const userData = JSON.parse(localStorage.getItem('userData'))

    const cacheApi = await getCacheableApi()

    // preload master data
    try {
      const preloadedData = await preloadMasterData(message => {
        this.loadingMessage = message
      })
      this.masterNegara = preloadedData.masterNegara
      this.masterJenisKartuIdentitas = preloadedData.masterJenisKartuIdentitas
      this.masterAgama = preloadedData.masterAgama
      this.masterGender = preloadedData.masterGender
      this.masterStrukturOrganisasi = preloadedData.masterStrukturOrganisasi
      this.masterJenisDokumen = preloadedData.masterJenisDokumen
      this.masterPropinsi = preloadedData.masterPropinsi
    } catch (error) {
      console.log(error.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
      return
    }

    this.loadingMessage = 'Loading Biodata...'

    // cek role yang diakui sistem
    if (!isRoleAllowed()) {
      this.loadingMessage = 'No Access'
      return
    }

    // determine biodataID
    if (
      userData.default_role === 'user' ||
      userData.default_role === 'member'
    ) {
      try {
        this.params.biodataID = await getMyBiodataID()
      } catch (error) {
        this.loadingMessage = error.message
        return
      }
    }
    if (
      userData.default_role === 'verifikator' ||
      userData.default_role === 'admin' ||
      userData.default_role === 'superadmin' ||
      userData.default_role === 'operator'
    ) {
      this.params.biodataID = this.$route.params.biodata_id
        ? this.$route.params.biodata_id
        : null
      // if (this.params.biodataID === '0' || !this.params.biodataID) {
      //   this.loadingMessage = 'Untuk admin, harus ada param url biodata_id'
      //   return
      // }
    }
    // -------- end determine biodata id

    // determine jenis permohonan id
    this.params.jenisPermohonanID = this.$route.params.jenis_permohonan_id
      ? this.$route.params.jenis_permohonan_id
      : null

    // determine permohonanID
    this.params.permohonanID = this.$route.params.permohonan_id
      ? this.$route.params.permohonan_id
      : null

    // determine mode
    this.params.mode = this.$route.params.mode ? this.$route.params.mode : null

    // cek mode
    if (this.params.mode === 'pengajuan') {
      if (!this.params.jenisPermohonanID) {
        this.loadingMessage = 'Tidak ada param jenis permohonan'
        return
      }
      if (this.params.jenisPermohonanID === '3') {
        if (!this.params.biodataID) {
          this.loadingMessage =
            'Permohonan data ulang harus memiliki eksisting biodata'
          return
        }

        try {
          // get biodata
          this.loadingMessage = 'Loading Biodata...'
          const biodata = await api().get(`biodata/${this.params.biodataID}`)
          if (!biodata.data) {
            this.loadingMessage = `Tidak ditemukan biodata dengan id: ${this.$route.params.id}`
            return
          }
          this.masterBiodata = biodata.data
          if (!this.masterBiodata.nia) {
            alert('Anda belum memiliki NIA')
            this.$router.replace({ name: 'permohonan-list' })
            return
          }

          // check dulu apakah ada permohonan yang sedang diajukan
          this.loadingMessage = 'Checking existing permohonan...'
          const exist = await cekEksistingPermohonan(
            this.params.jenisPermohonanID,
            this.params.biodataID,
          )
          if (exist) {
            this.loadingMessage = 'Sudah ada permohonan sedang diajukan.'
            return
          }

          this.loadingMessage = 'Checking existing draft permohonan'
          this.masterPermohonan = await getEksistingDraftPermohonan(
            this.params.jenisPermohonanID,
            this.params.biodataID,
          )
        } catch (error) {
          console.log('Error: ')
          console.log(error)
          this.loadingMessage = 'Something went wrong. Please refresh browser'
          return
        }
        this.showTab.foto = true
        this.showTab.ijasah = true
        this.showTab.lainnya = true
        this.showTab.buktiBayar = true
      }

      // Pengajuan Permohonan Verifikasi
      if (this.params.jenisPermohonanID === '2') {
        console.log('do something with Pengajuan Permohonan Verifikasi')
        if (!this.params.biodataID) {
          this.masterBiodata = newBiodata()
        } else {
          try {
            // get biodata
            this.loadingMessage = 'Loading Biodata...'
            const biodata = await api().get(`biodata/${this.params.biodataID}`)
            if (!biodata.data) {
              this.loadingMessage = `Tidak ditemukan biodata dengan id: ${this.$route.params.id}`
              return
            }
            this.masterBiodata = biodata.data
            if (this.masterBiodata.nia) {
              alert(
                'Anda telah memiliki NIA, tidak dapat mengajukan permohonan Verifikasi',
              )
              this.$router.replace({ name: 'permohonan-list' })
              return
            }
          } catch (error) {
            console.log('Error: ')
            console.log(error)
            this.loadingMessage = 'Something went wrong. Please refresh browser'
            return
          }
        }
        this.showTab.foto = true
        this.showTab.ijasah = true
        this.showTab.lainnya = true
        this.showTab.buktiBayar = true
      }
    }

    // ---------- MODE: DETIL
    if (this.params.mode === 'detil') {
      if (!this.params.permohonanID) {
        this.loadingMessage = 'Tidak ada permohonan id'
        return
      }
      // get detil permohonan
      this.loadingMessage = 'Loading permohonan...'
      let result
      try {
        result = await api().get(`permohonan/${this.params.permohonanID}`)
        this.masterPermohonan = result.data

        // masterPermohonan can be null if permohonanID not found
        if (!this.masterPermohonan) {
          this.loadingMessage = 'Permohonan not found'
          return
        }
      } catch (error) {
        this.loadingMessage = error.response.data.message
        return
      }
      // if (
      //   userData.default_role === 'user' ||
      //   userData.default_role === 'member'
      // ) {
      //   if (result.data.is_draft) {
      //     // this.$router.replace({
      //     //   name: 'mandiri-permohonan-pengajuan',
      //     //   params: {
      //     //     jenis_permohonan_id: result.data.jenis_permohonan_id,
      //     //     biodata_id: result.data.biodata_id,
      //     //     mode: 'pengajuan',
      //     //   },
      //     // })
      //     this.loadingMessage = 'No Access'
      //     return
      //   }
      //   this.loadingMessage = 'No Access'
      //   this.loadingMessage = ''
      //   this.noAccess = true
      //   return
      // }

      this.params.jenisPermohonanID = this.masterPermohonan.jenis_permohonan_id
      this.params.biodataID = this.masterPermohonan.biodata_id

      try {
        // get biodata
        this.loadingMessage = 'Loading Biodata...'
        const biodata = await api().get(`biodata/${this.params.biodataID}`)
        if (!biodata.data) {
          this.loadingMessage = `Tidak ditemukan biodata dengan id: ${this.$route.params.id}`
          return
        }
        this.masterBiodata = biodata.data
      } catch (error) {
        alert(error.response.data.message)
        this.loadingMessage = 'Something went wrong. Please refresh browser'
        return
      }
      this.showTab.foto = true
      this.showTab.ijasah = true
      this.showTab.lainnya = true
      this.showTab.buktiBayar = true
    }
    // ---------- END MODE: DETIL

    this.loadingMessage = 'Loading Persyaratan...'
    try {
      await cacheApi.get(
        `permohonan/persyaratan/${this.params.jenisPermohonanID}`,
      )
    } catch (error) {
      alert(error.response.data.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
      return
    }

    // if (this.params.mode === 'pengajuan') {
    //   // check dulu apakah ada permohonan yang sedang diajukan
    //   this.loadingMessage = 'Checking existing permohonan...'
    //   if (this.params.biodataID) {
    //     try {
    //       const eksistings = await api().get(
    //         `permohonan/cek-eksisting/${this.params.jenisPermohonanID}/${this.params.biodataID}`,
    //       )
    //       if (eksistings.data.length > 0) {
    //         this.loadingMessage = 'Sudah ada permohonan sedang diajukan.'
    //         return
    //       }
    //     } catch (error) {
    //       this.loadingMessage = error.message
    //       return
    //     }
    //     this.loadingMessage = 'Checking existing draft permohonan'
    //     try {
    //       const drafts = await api().post('permohonan/search', {
    //         jenis_permohonan_id: this.params.jenisPermohonanID,
    //         biodata_id: this.params.biodataID,
    //         is_draft: true,
    //       })
    //       if (drafts.data.length > 0) {
    //         ;[this.masterPermohonan] = drafts.data
    //       } else {
    //         // create new draft permohonan
    //         this.loadingMessage = 'No draft found. Creating new one...'
    //         try {
    //           const newDraft = await api().post('permohonan', {
    //             jenis_permohonan_id: this.params.jenisPermohonanID,
    //             biodata_id: this.params.biodataID,
    //             is_draft: true,
    //           })
    //           this.masterPermohonan = newDraft.data
    //         } catch (error) {
    //           this.loadingMessage = error.response.data.message
    //           return
    //         }
    //       }
    //     } catch (error) {
    //       this.loadingMessage = error
    //       return
    //     }
    //   }
    // }

    // does user has biodata?
    // if no, then create new biodata
    // if (!this.params.biodataID) {
    //   if (this.params.jenisPermohonanID === '3') {
    //     this.loadingMessage = 'Anda belum memiliki Biodata'
    //     return
    //   }
    //   // lets create new biodata for user
    //   this.masterBiodata = {
    //     id: null,
    //     nama_lengkap: null,
    //     gelar_depan: null,
    //     gelar_belakang: null,
    //     agama_id: null,
    //     jenis_kartu_identitas_id: 1,
    //     nomor_kartu_identitas: null,
    //     gender_id: 1,
    //     tempat_lahir: null,
    //     tanggal_lahir: null,
    //     nomor_hp: null,
    //     email: null,
    //     nama_ktpa: null,
    //     warga_negara_id: 'id',
    //     pekerjaan: null,
    //     dpn_dpc_id: null,
    //     keahlian_lainnya: '',
    //     is_published: true,
    //     is_advokat: false,
    //     nia: null,
    //     anggota_sejak: null,
    //     tanggal_berakhir: null,
    //     is_pengakhiran: false,
    //     is_pengurus: false,
    //     is_kehormatan: false,
    //     created_by: null,
    //     tanggal_nia: null,
    //     daftar_alamat: [],
    //     daftar_dokumen: [],
    //   }
    // }
    // else {
    //   try {
    //     const biodata = await api().get(`biodata/${this.params.biodataID}`)
    //     if (!biodata.data) {
    //       // alert('Tidak ada biodata ditemuakan')
    //       this.loadingMessage = `Tidak ditemukan biodata dengan id: ${this.$route.params.id}`
    //       return
    //     }
    //     this.masterBiodata = biodata.data
    //   } catch (error) {
    //     this.loadingMessage = 'Something went wrong. Please refresh browser'
    //     return
    //   }
    // }
    // if (this.params.jenisPermohonanID === '3' && !this.masterBiodata.nia) {
    //   alert('Anda belum memiliki NIA')
    //   this.$router.replace({ name: 'permohonan-list' })
    //   return
    // }
    // // show hide tab
    // if (this.params.jenisPermohonanID === '3') {
    //   this.showTab.foto = true
    //   this.showTab.ijasah = true
    //   this.showTab.lainnya = true
    //   this.showTab.buktiBayar = true
    // }

    // if (this.$route.params.mode === 'baru') {
    //   // NEW DATA
    // } else {
    //   console.log('EDITING')
    //   // const cloneBiodata = JSON.parse(JSON.stringify(this.masterBiodata))
    //   // console.log('CLONE')
    //   // console.log(cloneBiodata)
    // }

    // get persyaratan
    try {
      const results = await cacheApi.get(
        `permohonan/persyaratan/${this.params.jenisPermohonanID}`,
      )
      results.data.forEach(element => {
        let isUploaded = false
        const tmp = this.masterBiodata.daftar_dokumen.filter(
          dok => dok.jenis_dokumen_id === element.jenis_dokumen.id,
        )
        console.log('HASIL FILTER')
        console.log(tmp)
        if (tmp.length > 0) {
          isUploaded = true
        }

        this.masterDaftarPersyaratan.push({
          jenis_dokumen_id: element.jenis_dokumen.id,
          nama: element.jenis_dokumen.nama,
          uploaded: isUploaded,
        })

        // tambahkan ke dokumen
        if (!isUploaded) {
          const newDok = {
            biodata_id: this.masterBiodata.id,
            jenis_dokumen_id: element.jenis_dokumen.id,
            verification_status: 0,
            data: {},
            jenis_dokumen: element.jenis_dokumen,
          }
          this.masterBiodata.daftar_dokumen.push(newDok)
        }
      })
    } catch (error) {
      alert(error)
      return
    }
    this.masterBiodata.daftar_dokumen.forEach(element => {
      console.log('ELEMENT DAFTAR')
      console.log(element)
      if (element.jenis_dokumen_id === '1') {
        this.masterDaftarPKPA.push(element)
      }
      if (element.jenis_dokumen_id === '2') {
        this.masterDaftarUPA.push(element)
      }
      if (element.jenis_dokumen_id === '23') {
        this.masterDaftarIjasah.push(element)
      }
      if (element.jenis_dokumen_id === '3') {
        this.masterDaftarMagang.push(element)
      }
      if (element.jenis_dokumen_id === '4') {
        this.masterDaftarPengangkatanAdvokat.push(element)
      }
      if (element.jenis_dokumen_id === '5') {
        this.masterDaftarBas.push(element)
      }
      if (element.jenis_dokumen_id === '22') {
        this.masterDaftarFoto.push(element)
      }
      if (element.jenis_dokumen.tipe === '2') {
        const found = this.masterDaftarPersyaratan.find(
          item => item.jenis_dokumen_id === element.jenis_dokumen_id,
        )
        if (found) {
          this.masterDokumenLainnya.push(element)
        }
      }
    })
    this.showDokumen = true
  },
  methods: {
    showChecklistVerifikasi() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role !== 'verifikator' &&
        userData.default_role !== 'admin' &&
        userData.default_role !== 'superadmin'
      ) {
        return false
      }
      return true
    },
  },
}
</script>
