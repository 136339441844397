<template>
  <b-sidebar
    id="sidebar-verifikasi-dokumen"
    ref="sidebarVerifikasiDokumen"
    title="Verifikasi"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    backdrop
    shadow
    @shown="onShown"
  >
    <div class="px-3 py-2">
      <p>
        <!-- {{ localDokumen }} -->
      </p>
      <b-form-group label="Hasil Verifikasi" label-for="verification-status">
        <b-form-select
          v-model="selectedVerificationStatus"
          :options="optionsVerificationStatus"
          class="mb-1"
        />
      </b-form-group>
      <b-form-group label="Keterangan" label-for="keterangan">
        <b-form-textarea
          v-model="catatan"
          placeholder="Tulis Keterangan Disini"
          rows="5"
          trim
        />
      </b-form-group>
      <div class="mb-1">
        &nbsp;&nbsp;.
      </div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :disabled="btnSimpanDisabled"
        @click="simpan"
      >
        <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
        <span class="sr-only">Loading...</span>
        Simpan
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-warning"
        class="ml-2"
        @click="cancel"
      >
        Batal
      </b-button>
      <!-- <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
          in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
        </p>
        <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img> -->
    </div>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import emitter from '@/hap-sia/emitter'
import { getCacheableApi, api } from '@/hap-sia/setup'

export default {
  components: {
    BSidebar,
    // BImg,
    // BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BButton,
    BSpinner,
    // BOverlay,
  },
  directives: {
    Ripple,
  },
  props: ['masterDokumen'],
  data() {
    return {
      localDokumen: null,
      selectedVerificationStatus: null,
      optionsVerificationStatus: null,
      catatan: null,
      btnSimpanSpinnerShow: false,
      btnSimpanDisabled: false,
    }
  },
  async mounted() {
    // alert('mounted')
    // let's delete the event to avoid double register
    // emitter.all.delete('VERIFIKASI')
    /* eslint-disable no-underscore-dangle */
    // this.sidebarId = this._uid
    emitter.on('VERIFIKASI', async e => {
      this.localDokumen = e
      this.$root.$emit('bv::toggle::collapse', 'sidebar-verifikasi-dokumen')
      // console.log('VERIFIKASI', e)
      // check if options status verifikasi gagal load
      // if (this.optionsVerificationStatus.length < 2) {
      //   try {
      //     const resp = await cacheApi.get('master/status-verifikasi')
      //     this.optionsVerificationStatus = [
      //       { value: null, text: '-- Pilih Status Verifikasi --' },
      //     ]

      //     resp.data.forEach(element => {
      //       this.optionsVerificationStatus.push({
      //         value: element.id,
      //         text: element.nama,
      //       })
      //     })
      //   } catch (error) {
      //     alert(error)
      //     return
      //   }
      // }
      // this.localDokumen.verification_status = '1'
    })
  },
  methods: {
    cancel() {
      this.selectedVerificationStatus = null
      this.$root.$emit('bv::toggle::collapse', 'sidebar-verifikasi-dokumen')
    },
    async simpan() {
      this.btnSimpanSpinnerShow = true
      this.btnSimpanDisabled = true
      // await new Promise(r => setTimeout(r, 2000))
      let result

      if (this.localDokumen.is_permohonan) {
        const userData = JSON.parse(localStorage.getItem('userData'))
        try {
          result = await api().post('payment/verifikasi_bukti_bayar', {
            id: this.localDokumen.id,
            status_id: this.selectedVerificationStatus,
            catatan: this.catatan,
            user: userData.username,
          })
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          return
        }
      } else {
        try {
          result = await api().post('verifikasi', {
            dok_id: this.localDokumen.id,
            status_id: this.selectedVerificationStatus,
            catatan: this.catatan,
          })
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          return
        }
      }
      this.localDokumen.verification_status = this.selectedVerificationStatus
      emitter.emit('VERIFICATION_UPDATED', result.data)
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
      this.btnSimpanSpinnerShow = false
      this.btnSimpanDisabled = false
      this.selectedVerificationStatus = null
      this.$root.$emit('bv::toggle::collapse', 'sidebar-verifikasi-dokumen')
    },
    async onShown() {
      if (
        !this.optionsVerificationStatus ||
        this.optionsVerificationStatus.length === 1
      ) {
        this.optionsVerificationStatus = [{ value: null, text: 'Loading...' }]
      }
      const cacheApi = await getCacheableApi()
      try {
        const resp = await cacheApi.get('master/status-verifikasi')
        this.optionsVerificationStatus = [
          { value: null, text: '-- Pilih Status Verifikasi --' },
        ]

        resp.data.forEach(element => {
          this.optionsVerificationStatus.push({
            value: element.id,
            text: element.nama,
          })
        })
      } catch (error) {
        alert(error)
        // return
      }
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
