<template>
  <div>
    <!-- masterDaftarDokumen
    <br>
    {{masterDaftarDokumen}}
    <br>
    masterDaftarPersyaratan
    <br>
    {{masterDaftarPersyaratan}} -->
    <!-- <br>
    Selected Persyaratan
    <br>
    {{selectedPersyaratan}} -->
    <!-- Master Permohonan
    <br>
    {{masterPermohonan}}
    <br> -->
    <b-card title="Checklist Persyaratan" class="mb-2">
      <b-card-text>
        Persyaratan Permohonan Keanggotaan.
      </b-card-text>

      <b-form-group v-slot="{ ariaDescribedby }">
        <div v-if="processing" class="d-flex justify-content-center m-2">
          <b-spinner label="Loading..." />
        </div>
        <b-form-checkbox-group
          v-else
          id="checkbox-group-1"
          v-model="selectedPersyaratan"
          :options="optionsPersyaratan"
          :aria-describedby="ariaDescribedby"
          stacked
          disabled="disabled"
          name="flavour-1"
        />
      </b-form-group>
      <b-button
        v-if="showReload"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="recheckPersyaratan"
      >
        Reload Kelengkapan
      </b-button>
    </b-card>
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import {
  BCard,
  BButton,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BSpinner,
} from 'bootstrap-vue'
import { getCacheableApi, api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  // props: ['masterPermohonan', 'jenisPermohonanId', 'masterDaftarPersyaratan', 'masterDaftarDokumen'],
  props: ['biodataID', 'jenisPermohonanId', 'masterPermohonan'],
  data() {
    return {
      selectedPersyaratan: [],
      optionsPersyaratan: [],
      showReload: false,
      processing: true,
    }
  },
  async mounted() {
    if (!this.biodataID) {
      const cacheApi = await getCacheableApi()
      let persyaratan
      try {
        persyaratan = await cacheApi.get('permohonan/persyaratan/3')
        console.log('PERSYARATAN')
        console.log(persyaratan)
        this.processing = false

        persyaratan.data.forEach(element => {
          this.optionsPersyaratan.push({
            value: element.jenis_dokumen.id,
            text: element.jenis_dokumen.nama,
          })
        })
        this.optionsPersyaratan.push({
          value: 'bukti_bayar',
          text: 'Pembayaran',
        })
      } catch (error) {
        // alert(error)
        this.processing = false
        this.showReload = true
      }
    } else {
      this.recheckPersyaratan()
    }

    emitter.on('BIODATA_DOKUMEN_TERSIMPAN', () => {
      console.log('DOKUMEN BERHASIL TERSIMPAN ahaii')
      // this.selectedPersyaratan.push(e.jenis_dokumen_id)
      this.recheckPersyaratan()
    })
    emitter.on('BIODATA_DOKUMEN_DELETED', () => {
      console.log('DOKUMEN BERHASIL DELETED')
      // this.selectedPersyaratan.push(e.jenis_dokumen_id)
      this.recheckPersyaratan()
    })
    emitter.on('BUKTI_BAYAR_TERSIMPAN', () => {
      this.recheckPersyaratan()
    })
    emitter.on('BUKTI_BAYAR_DELETED', () => {
      this.recheckPersyaratan()
    })

    // this.masterDaftarPersyaratan.forEach(element => {
    //   this.optionsPersyaratan.push({
    //     value: element.jenis_dokumen_id,
    //     text: element.nama,
    //   })
    //   if (element.uploaded) {
    //     this.selectedPersyaratan.push(element.jenis_dokumen_id)
    //   }
    // })
  },
  methods: {
    async recheckPersyaratan() {
      // const tmp = this.masterDaftarDokumen.filter(dok => (dok.jenis_dokumen_id === element.jenis_dokumen.id))

      // this.masterDaftarPersyaratan.forEach(item => {
      //   const found = this.masterDaftarDokumen.find(dok => dok.jenis_dokumen_id === item.jenis_dokumen_id)
      //   if (found && Object.keys(found.data).length > 0) {
      //     console.log(found)
      //     item.uploaded = true
      //     this.selectedPersyaratan.push(found.jenis_dokumen_id)
      //   } else {
      //     item.uploaded = false
      //   }
      // })
      this.processing = true
      this.showReload = false

      try {
        // alert(this.jenisPermohonanID)
        let result
        if (this.masterPermohonan) {
          result = await api().get('permohonan/cek-kelengkapan', {
            params: {
              permohonan_id: this.masterPermohonan.id,
            },
          })
        } else {
          result = await api().get('permohonan/cek-kelengkapan', {
            params: {
              jenis_permohonan_id: this.jenisPermohonanId,
              biodata_id: this.biodataID,
            },
          })
        }

        this.processing = false
        this.showReload = false

        this.optionsPersyaratan.splice(0, this.optionsPersyaratan.length)
        this.selectedPersyaratan.splice(0, this.selectedPersyaratan.length)

        result.data.daftar_dokumen.forEach(element => {
          this.optionsPersyaratan.push({
            value: element.jenis_dokumen_id,
            text: element.nama,
          })
          if (element.uploaded) {
            this.selectedPersyaratan.push(element.jenis_dokumen_id)
          }
        })

        result.data.daftar_bukti_bayar.forEach(element => {
          this.optionsPersyaratan.push({
            value: element.jenis_dokumen_id,
            text: element.nama,
          })
          if (element.uploaded) {
            this.selectedPersyaratan.push(element.jenis_dokumen_id)
          }
        })

        // results.data.forEach(element => {
        //   this.optionsPersyaratan.push({
        //     value: element.jenis_dokumen_id,
        //     text: element.nama,
        //   })
        //   if (element.uploaded) {
        //     this.selectedPersyaratan.push(element.jenis_dokumen_id)
        //   }
        // })
        // this.optionsPersyaratan.push({
        //   value: 'bukti_bayar',
        //   text: 'Bukti Bayar',
        // })
      } catch (error) {
        // alert(error)
        this.processing = false
        this.showReload = true
      }
    },
  },
}
</script>
